<template>
  <div>
    <q-breadcrumbs
        v-if="breadcrumbs.length > 0"
        class="q-mb-md"
    >
      <q-breadcrumbs-el
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
          :to="breadcrumb.to"
          :label="breadcrumb.label"
      />
    </q-breadcrumbs>
  </div>
</template>

<script lang="ts" setup>

import {useRouter} from "vue-router";
import {computed} from "vue";

const router = useRouter();

const props = defineProps<{
  activeColor?: string,
  separator?: string,
  style?: string
}>()

const breadcrumbs = computed(() => {
  const fullPath = router.currentRoute.value.fullPath
  const fullRouteParts = fullPath.split('/').filter(part => part !== '')

  const routes: {
    to: string;
    label: string;
  }[] = [{
    label: 'Home',
    to: '/'
  }]

  const matchedRoute = router.resolve({path: fullPath})

  matchedRoute.matched.forEach((route) => {
    const routeName = route.meta.title as string
    const routePath = route.path
    
    // get the route path for the current route
    const routePathParts = routePath.split('/').filter(part => part !== '')
    const routePathPartsLength = routePathParts.length
    const currentRoutePath = fullRouteParts.slice(0, routePathPartsLength).join('/')
    
    if(route.children.length > 0 || routePath === '/') {
      return 
    }
    
    routes.push({
      label: routeName,
      to: '/' + currentRoutePath,
    })
  })
  
  return routes
})


</script>