import { createApp } from 'vue'
import { Quasar, Notify } from 'quasar'
import { createAuth0 } from '@auth0/auth0-vue';
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
// Import Quasar css
import 'quasar/src/css/index.sass'
import './style.css'

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue'
import router from './router/router';

const myApp = createApp(App)

myApp.use(createPinia())

myApp.use(Quasar, {
  plugins: {
    Notify
  }, // import Quasar plugins and add here,
  config: {
    notify: {
      position: "bottom-right",
      color: "grey-11",
      actions: [{ icon: 'close', color: 'white' }],
    }
  }
})

myApp.use(createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
  cacheLocation: 'localstorage',
}))


Sentry.init({
  app: myApp,
  dsn: "https://9ec59e984da3ff46462a00ec0bbb99f4@o4506328742821888.ingest.sentry.io/4506328860590080",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", import.meta.env.VITE_API_URL],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 0.0 , // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0.0 , // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

myApp.use(router)

myApp.mount('#crcbr-app')
