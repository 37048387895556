<template>
  <div class="container mx-auto">
    <div v-html="content" class="prose max-w-none prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none">
    </div>
  </div>
</template>
<script lang="ts" setup>

import {useApplicationStore} from "../../../stores/appStore";
import {storeToRefs} from "pinia";
import {ref, watch} from "vue";

const appStore = useApplicationStore();

const {staticLoadedLast} = storeToRefs(appStore)
const {staticContentForPage} = appStore

const props = defineProps<{
  pageName: string
}>()

const content = ref('')

watch(() => staticLoadedLast, () => {
  if (staticLoadedLast.value) {
    content.value = staticContentForPage(props.pageName)?.content ?? 'Not Found';
  }
}, {immediate: true})

</script>