import { useAuth0 } from "@auth0/auth0-vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { CustomClaims } from "../enums/CustomClaims";
import {computed, ref} from "vue";
import {ProducerDto} from "../types/models/producer/ProducerDto";
import useApiClient from "../utils/api_client/ApiClient";
import {StaticContentDto} from "../types/models/staticContent/StaticContentDto";
import {StaticContentVersionDto} from "../types/models/staticContent/StaticContentVersionDto";

export const useApplicationStore = defineStore('application', () => {
    const { isAuthenticated, idTokenClaims } = useAuth0()
    
    const apiClient = useApiClient()

    const isAdmin = computed(() => idTokenClaims.value?.[CustomClaims.IS_ADMIN] ?? false)
    
    const firmId = computed(() => idTokenClaims.value?.[CustomClaims.ASSOCIATED_FIRM] ?? null)
    
    const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string
    
    const staticContent = ref<StaticContentVersionDto[]>([
        {
            name: 'Home',
            content: 'Loading...',
        },
        {
            name: 'About',
            content: 'Loading...',
        },
        {
            name: 'Contact',
            content: 'Loading...',
        },
        {
            name: 'faq',
            content: 'Loading...',
        }
    ])
    
    const staticLoadedAt = ref<Date | null>(null)

    const checkAdminLogin = () => {
        const router = useRouter()
        const routeIsForAdmin = router.currentRoute.value.path.startsWith('/admin')

        if( !isAuthenticated || (routeIsForAdmin && ! isAdmin.value)) {
            router.push({ name: 'Home' })
            return
        }
    }
    
    const producerProfileVal = ref<ProducerDto>()
    
    const setProducerProfile = (profile: ProducerDto) => {
        producerProfileVal.value = profile
    }
    
    const producerProfile = computed(() => producerProfileVal.value) 
    
    const profileNeedsUpdate = computed(() => {
        if(!producerProfile.value || isAdmin.value) {
            return false
        }
        
        return !producerProfile.value?.firmId
    })
    
    const loadProducerProfile = async () => {
        apiClient.getRequest<ProducerDto>('producer/profile').then((response) => {
            setProducerProfile(response.data)
        })
    }
    
    const loadStaticContent = async () => {
        if(!!staticLoadedAt.value) {
            return;
        }
        
        const storedContent = localStorage.getItem('staticContent')
        if(storedContent) {
            staticContent.value = JSON.parse(storedContent)
        }
        
        apiClient.getRequest<StaticContentDto[]>('general/static-content').then((response) => {
            staticContent.value = response.data
            staticLoadedAt.value = new Date()
        })
        
        localStorage.setItem('staticContent', JSON.stringify(staticContent.value))
    }
    
    const staticContentForPage = (page: string) => {
        return staticContent.value.find((content) => content.name.toLowerCase() === page.toLowerCase())
    }
    
    const staticLoadedLast = computed(() => staticLoadedAt.value)
    
    const isLoggedIn = computed(() => !!idTokenClaims.value)
    
    const apiBaseUrl = `${import.meta.env.VITE_API_URL}/api/v${import.meta.env.VITE_DEFAULT_API_VERSION}` as string

    return { checkAdminLogin, isAdmin, stripePublicKey, apiBaseUrl, isLoggedIn, firmId, loadProducerProfile, 
        producerProfile, profileNeedsUpdate, staticContentForPage, loadStaticContent, staticLoadedLast }
})