<script setup lang="ts">
import {useAuth0} from '@auth0/auth0-vue';
import {useApplicationStore} from './stores/appStore';
import {storeToRefs} from 'pinia';
import { useRouter} from "vue-router";
import {onMounted, watch} from "vue";
import vertLogImage  from '../src/assets/crcbr_logo_vert.jpg';
import RouterBreadCrumbs from "./components/general/RouterBreadCrumbs.vue";

const {isAuthenticated, loginWithRedirect, logout, user, idTokenClaims } = useAuth0();
const applicationStore = useApplicationStore();
const {isAdmin, isLoggedIn, producerProfile, profileNeedsUpdate } = storeToRefs(applicationStore);
const {loadProducerProfile, loadStaticContent} = applicationStore

const router = useRouter();


watch(isLoggedIn, async (isAuthenticated) => {

  const routeIsHome = window.location.pathname === '/'

  if (isAuthenticated) {
    if(!isAdmin){
      loadProducerProfile()
    }

    if (!routeIsHome) {
      return;
    }

    if (isAdmin.value) {
      await router.push({name: 'AdminDashboard'});
      return;
    }
    await router.push({name: 'Applications'});
  }
})

watch(producerProfile, async (producerProfile) => {
  if (!producerProfile?.firmId) {
    await router.push({name: 'Profile'});
  }
})

const loginAction = async () => {
  await loginWithRedirect({
    appState: {
      targetUrl: router.currentRoute.value.fullPath,
    },
  });
}

const logoutAction = async () => {
  await logout({
    logoutParams: {
      returnTo: window.location.origin,
    }
  });
}

onMounted(async () => {
  await loadStaticContent()
})

</script>

<template>
  <div class="sticky top-0 border-b shadow-sm bg-white z-30">
    <!-- Header -->
    <header class="flex justify-between h-24 items-center mx-4 lg:max-w-6xl lg:mx-auto">
      <div class="">
        <router-link :to="{ name: 'Home' }">
          <img :src="vertLogImage" class="logo" alt="Vertical CRCBR logo" fit="contain"/>
        </router-link>
      </div>
      <div class="flex-1 flex mx-auto justify-around">
        <div class="text-sm">
          <router-link :to="{ name: 'About' }">About</router-link>
        </div>
        <div class="text-sm">
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
        </div>
      </div>
      <div class="flex space-x-4 items-center">
        <div class="flex items-center space-x-2">
          <div v-if="isAuthenticated" class="text-sm flex">
            <q-btn no-caps flat>
              <q-menu>
                <q-item>{{ user?.email }}</q-item>
                <q-separator/>
                <q-item v-if="!isAdmin" clickable to="/applications">Applications</q-item>
<!--                <q-item clickable to="/settings">Settings</q-item>-->
                <q-item v-if="isAdmin" clickable to="/admin">Admin</q-item>
                <q-item v-else clickable to="/profile">Profile</q-item>
              </q-menu>
              <span class="truncate w-32">{{ user?.given_name ?? user?.name }}</span>
            </q-btn>
            <div class="text-sm">
              <q-btn color="primary" no-caps @click="logoutAction">Logout</q-btn>
            </div>
          </div>
          <div class="text-sm" v-else>
            <q-btn color="primary" no-caps @click="loginAction">Login</q-btn>
          </div>
        </div>
      </div>
    </header>
  </div>

  <q-banner v-if="profileNeedsUpdate" class="bg-red-8 text-white text-center">

    <div class="text-xl">You need to update your profile before you can submit an application.</div>
    
    <template v-slot:action>
        <q-btn color="white" text-color="red" no-caps :to="{ name: 'Profile' }" label="Complete Profile" />
    </template>
  </q-banner>

  <main class="mx-auto md:max-w-6xl">
    <router-bread-crumbs />
    <router-view></router-view>
  </main>
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
