﻿<template>

  <q-card>
    <q-card-section>
      <q-form @submit="createSubmission">
        <div class="my-4 text-lg text-center">
          You are submitting for the <span class="text-bold">{{ cycle?.year ?? new Date().getFullYear() + 1 ?? 'No Cycle Set' }}</span> Circle of Excellence Awards.
        </div>
        
        <div class="text-bold">All award winners will be featured in the Charlotte Business Journal Commercial Real
          Estate
          Quarterly on May 31 2024. Winners will be listed alphabetically by volume credit category (no
          dollar totals). Top Producers for each category shall be recognized with a picture. Runners-up
          will be listed alphabetically by name and company.
        </div>
        <q-option-group v-model="createSubmissionRequest.optInToFeature" :options="[
          {label: 'Opt In (name will appear in the CBJ)', value: true},
          {label: 'Opt Out (name WILL NOT appear in the CBJ)', value: false},
        ]"/>

        <q-separator class="my-6"/>

        <div class="text-bold">Have you been in commercial real estate for less than four (4) years (for consideration
          for the Broker-to-Watch award)?
        </div>

        <q-option-group v-model="createSubmissionRequest.commercialRealtorLessThan4Years" :options="[
          {label: 'Yes', value: true},
          {label: 'No', value: false},
        ]" label="Commercial Realtor Less Than 4 Years"/>

        <q-separator class="my-6"/>

        <div class="text-bold">Circle of Excellence Award Ceremony is scheduled for Wednesday, March 6, 2024 at the
          Terrace at
          Cedar Hill. All applicants receive complimentary registration for this luncheon.
        </div>

        <q-option-group v-model="createSubmissionRequest.willAttendCeremony" :options="[
          {label: 'Yes, I plan to attend ', value: true},
          {label: 'No, I won’t attend', value: false},
        ]" label="Will Attend Ceremony"/>

        <p class="mt-8 text-bold">
          Person completing this form (if not applicant)
        </p>
        <q-input v-model="createSubmissionRequest.preparerName" label="Preparer Name" hint="(optional)"/>
        <q-input v-model="createSubmissionRequest.preparerEmail" label="Preparer Email" hint="(optional)"/>

        <div class="text-center mt-6">
          <q-btn no-caps type="submit" label="Create Submission" color="primary"/>
        </div>

      </q-form>
    </q-card-section>
  </q-card>

</template>

<script setup lang="ts">

import {CreateSubmissionRequest} from "../../types/dto/submission/CreateSubmissionRequest";
import {ref} from "vue";
import {CycleDto} from "../../types/models/CycleDto";
import useApiClient from "../../utils/api_client/ApiClient";

const apiClient = useApiClient();

const emits = defineEmits<{
  (event: 'cancelled'): void,
  (event: 'submission-created'): void,
}>()

const props = defineProps<{
  cycle?: CycleDto,
  producerId?: string,
}>()

const createSubmissionRequest = ref<CreateSubmissionRequest>({
  optInToFeature: true,
  commercialRealtorLessThan4Years: false,
  willAttendCeremony: true,
  preparerName: undefined,
  preparerEmail: undefined
});

const createSubmission = async () => {
  
  if(props.producerId) {
    await apiClient.postRequest<any>('admin/submissions',  {
      producerId: props.producerId,
      cycleId: props.cycle?.id,
      optInToFeature: createSubmissionRequest.value.optInToFeature,
      commercialRealtorLessThan4Years: createSubmissionRequest.value.commercialRealtorLessThan4Years,
      willAttendCeremony: createSubmissionRequest.value.willAttendCeremony,
      preparerName: createSubmissionRequest.value.preparerName,
      preparerEmail: createSubmissionRequest.value.preparerEmail,
    });
    
    emits('submission-created')
    return;
  }

  await apiClient.postRequest<any>('producer/submissions',  {
    cycleId: props.cycle?.id,
    optInToFeature: createSubmissionRequest.value.optInToFeature,
    commercialRealtorLessThan4Years: createSubmissionRequest.value.commercialRealtorLessThan4Years, 
    willAttendCeremony: createSubmissionRequest.value.willAttendCeremony,
    preparerName: createSubmissionRequest.value.preparerName,
    preparerEmail: createSubmissionRequest.value.preparerEmail,
  });

  emits('submission-created')
};

</script>