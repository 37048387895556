﻿<template>
  <div>
    <q-btn v-if="asMenu" flat round dense icon="more_vert">
      <q-menu>
        <q-list>
          <q-item v-show="shouldShow(action)" clickable @click="handleClick(action)" v-ripple
                  v-for="action in actionsToShow" :key="action.label">
            <q-item-section>{{ action.label }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>

    <template v-else>
      <q-btn v-show="shouldShow(action)" v-for="(action, idx) in actionsToShow" :class="{'ml-2': idx > 0 }"
             :key="action.label" :label="action.label" @click="handleClick(action)" no-caps/>
    </template>

    <q-dialog v-model="showPaymentDialog" persistent>
      <SubmissionPayment :submission="props.submission" @payment-successful="showPaymentDialog = false; emits('payment-successful')"
                         @cancel="showPaymentDialog = false"/>
    </q-dialog>

    <q-dialog v-model="showRequestApprovalDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Are you sure you want to request approval for this application?</div>
        </q-card-section>
        <q-card-actions>
          <q-btn no-caps label="Cancel" class="w-full" outline color="primary"
                 @click="showRequestApprovalDialog = false"/>
          <q-btn no-caps label="Request Approval" class="w-full" color="primary" @click="requestApproval"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showApprovalDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Are you sure you want to approve this application?</div>
        </q-card-section>
        <q-card-actions>
          <q-btn no-caps label="Cancel" class="w-full" outline color="primary" @click="showApprovalDialog = false"/>
          <q-btn no-caps label="Approve" class="w-full" color="primary" @click="approve"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showSubmissionDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Are you sure you want to submit this application?</div>
        </q-card-section>
        <q-card-actions>
          <q-btn no-caps label="Cancel" class="w-full" outline color="primary" @click="showSubmissionDialog = false"/>
          <q-btn no-caps label="Submit" class="w-full" color="primary" @click="submitSubmission"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" setup>

import {useRouter} from "vue-router";
import {FrontEndLink} from "../../types/dto/AdminDashboardDto";
import {computed, ref} from "vue";
import {SubmissionDto} from "../../types/models/submission/SubmissionDto";
import SubmissionPayment from "../payment/SubmissionPayment.vue";
import useApiClient from "../../utils/api_client/ApiClient";
import {useApplicationStore} from "../../stores/appStore";

const router = useRouter()
const apiClient = useApiClient()
const {isAdmin} = useApplicationStore()

const emits = defineEmits<{
  (e: 'payment-successful'): void,
  (e: 'submission-successful'): void,
  (e: 'request-approval-successful'): void,
  (e: 'approval-successful'): void,
}>()

const props = withDefaults(defineProps<{
  actions: FrontEndLink[],
  submission: SubmissionDto,
  asMenu?: boolean,
}>(), {
  asMenu: false,
})

const actionsToShow = computed(() => {
  return props.actions.filter(action => shouldShow(action)).map(action => {
    return {
      ...action,
      label: (action.additional == 'actions.request-approval') ? 'Approve Submission' : action.label
    }
  })
})

const showPaymentDialog = ref(false)
const showRequestApprovalDialog = ref(false)
const showApprovalDialog = ref(false)
const showSubmissionDialog = ref(false)

const submitSubmission = async () => {
  try {
    await apiClient.postRequest(`/producer/submissions/${props.submission.id}/submit`, null)
    emits('submission-successful')
  } finally {
    showSubmissionDialog.value = false
  }
}

const requestApproval = async () => {
  try {
    await apiClient.postRequest(`/producer/submissions/${props.submission.id}/request-approval`, null)
    emits('request-approval-successful')
  } finally {
    showRequestApprovalDialog.value = false
  }
}

const approve = async () => {
  try {
    await apiClient.postRequest(`/producer/submissions/${props.submission.id}/approve`, null)
    emits('approval-successful')
  } finally {
    showApprovalDialog.value = false
  }
}

const shouldShow = (action: FrontEndLink) => {
  if (action.label == 'View') {
    return router.currentRoute.value.name != 'ViewSubmission'
  }

  return true
}

const handleClick = (action: FrontEndLink) => {
  if (!action.additional) {
    router.push(action.url)
    return;
  }

  switch (action.additional) {
    case 'actions.pay':
      showPaymentDialog.value = true
      break;
    case 'actions.submit':
      showSubmissionDialog.value = true
      break;
    case 'actions.request-approval':
      if (isAdmin) {
        showApprovalDialog.value = true
        break;
      }
      showRequestApprovalDialog.value = true
      break;
    case 'actions.approve':
      showApprovalDialog.value = true
      break;
  }
}

</script>