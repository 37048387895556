import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import ListApplicationsPage from '../views/producer/application/ListApplicationsPage.vue'
import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage,
    meta: {
      title: 'Home',
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'About',
      requiresAuth: false
    },
    component: () => import('../views/AboutPage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact',
      requiresAuth: false
    },
    component: () => import('../views/ContactPage.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: 'Admin',
      requiresAuth: true
    },
    component: () => import('../views/admin/AdminPage.vue'),
    children: [
      {
        path: '',
        name: 'AdminDashboard',
        meta: {
          title: 'Admin Dashboard',
          requiresAuth: true
        },
        component: () => import('../views/admin/AdminDashboardPage.vue')
      },
      {
        path: 'firms',
        name: 'ManageFirms',
        meta: {
          title: 'Manage Firms',
          requiresAuth: true
        },
        component: () => import('../views/admin/AdminManagerFirms.vue')
      },
      {
        path: 'producers',
        name: 'ManageProducers',
        meta: {
          title: 'Manage Producers',
          requiresAuth: true
        },
        component: () => import('../views/admin/AdminManageProducers.vue')
      },
      {
        path: 'producers/:producerId',
        name: 'ViewProducer',
        meta: {
          title: 'View Producer',
          requiresAuth: true
        },
        props: true,
        component: () => import('../views/admin/producer/AdminViewProducerPage.vue')
      },
      {
        path: 'categories',
        name: 'ManageCategories',
        meta: {
          title: 'Manage Categories',
          requiresAuth: true
        },
        component: () => import('../views/admin/AdminManageCategories.vue')
      },
      {
        path: 'submissions',
        name: 'ManageSubmissions',
        meta: {
          title: 'Manage Submissions',
          requiresAuth: true
        },
        props: (route: any) => ({ status: route.query.status }), 
        component: () => import('../views/admin/application/AdminViewApplications.vue')
      },
      {
        path: 'cycles',
        name: 'ManageCycles',
        meta: {
          title: 'Manage Cycles',
          requiresAuth: true
        },
        props: true,
        component: () => import('../views/admin/AdminManageCycles.vue')
      },
      {
        path: 'cycles/:cycleId',
        name: 'ManageCycle',
        meta: {
          title: 'Manage Cycle',
          requiresAuth: true
        },
        props: true,
        component: () => import('../views/admin/cycle/AdminViewCyclePage.vue')
      },
      {
        path: 'static-content',
        name: 'ManageStaticContent',
        meta: {
          title: 'Manage Static Content',
          requiresAuth: true
        },
        props: true,
        component: () => import('../views/admin/content/AdminManageContentPage.vue')
      }
    ]
  },
  {
    path: '/applications',
    name: 'Applications',
    meta: {
      title: 'Applications',
      requiresAuth: true
    },
    component: ListApplicationsPage
  },
  {
    path: '/submissions/:submissionId',
    name: 'ViewSubmission',
    meta: {
      title: 'View Submission',
      requiresAuth: true
    },
    props: true,
    component: () => import('../views/producer/submission/ViewSubmissionPage.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Profile',
      requiresAuth: true
    },
    component: () => import('../views/producer/profile/ProducerProfilePage.vue')
  }
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} | ${import.meta.env.VITE_APP_TITLE}`
  if(to.meta.requiresAuth !== false ) {
    await authGuard(to)
  }
  
  next()
})

export default router
