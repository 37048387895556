// api client composable
import {useAuth0} from '@auth0/auth0-vue'
import {Axios, AxiosRequestConfig, AxiosResponse} from 'axios'
import {Notify} from "quasar";

export type ApiClientOptions = {
  axiosConfig?: AxiosRequestConfig
}

export default function useApiClient(options: ApiClientOptions = {}) {
    const { getAccessTokenSilently } = useAuth0()
    
    const generateAccessToken = async () => {
        try {
            return await getAccessTokenSilently()
        } catch (e) {
            Notify.create({
                color: 'negative',
                message: 'There was an error generating your access token',
            })
            Notify.create({
                color: 'negative',
                message: 'Try logging out and back in again',
            })
            throw e
        }
    }

    const generateAxiosConfig = async (): Promise<AxiosRequestConfig> => {
        const accessToken = await generateAccessToken()
        return {
            ...options.axiosConfig,
            baseURL: `${import.meta.env.VITE_API_URL}/api/v${import.meta.env.VITE_DEFAULT_API_VERSION}`,
            headers: {
                ...options.axiosConfig?.headers,
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            validateStatus: () => true
        }
    }

    const createAxiosInstance = async (): Promise<Axios> => {
        return new Axios(await generateAxiosConfig())
    }

    const getRequest = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
        return handleResponseError(async() => {
            const axiosInstance = await createAxiosInstance()
            const response = await axiosInstance.get(url, config)
            return handleResponse(response) as AxiosResponse<T>
        })
    }

    const postRequest = async <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
        return handleResponseError(async() => {
            const axiosInstance = await createAxiosInstance()
            const response = await axiosInstance.post<T>(url, JSON.stringify(data), config)
            return handleResponse(response) as AxiosResponse<T>
        })
    }
    
    const putRequest = async <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
        return handleResponseError(async() => {
            const axiosInstance = await createAxiosInstance()
            const response = await axiosInstance.put(url, JSON.stringify(data), config)
            return handleResponse(response) as AxiosResponse<T>
        })
    }

    const deleteRequest = async (url: string, config?: AxiosRequestConfig) => {
        return handleResponseError(async() => {
            const axiosInstance = await createAxiosInstance()
            const response = await axiosInstance.delete(url, config)
            return handleResponse(response)
        })
    }
    
    const generateUrl = (url: string) => {
        return `${import.meta.env.VITE_API_URL}/api/v${import.meta.env.VITE_DEFAULT_API_VERSION}/${url}`
    }
    
    const handleResponse = (response: AxiosResponse) => {
        response.data = typeof response.data != 'object' ? JSON.parse(response.data): response.data;        
        
        if(response.data?.messages?.length > 0) {
            response.data.messages.forEach((message: string) => {
                Notify.create({
                    color: response.status >= 400 ? 'negative' : 'positive',
                    message: message,
                });
            });
        }
        
        if(response.status <= 404) {
            return response;
        }

        if(response.data?.messages?.length == 0) {
            Notify.create({
                color: 'negative',
                message: 'There was an error with your request',
            });
        }
        
        throw new Error("Unexpected API error", {
            cause: response
        });
    }
    
    const handleResponseError = async (callable: Function) => {
        try {
            return await callable();
        }catch (e) {
            Notify.create({
                color: 'negative',
                message: 'There was an error with your request',
            });
            throw e;
        }
    }

    return {
        createAxiosInstance,
        getRequest,
        postRequest,
        putRequest,
        deleteRequest,
        generateUrl,
        generateAccessToken,
        handleResponse,
        handleResponseError
    }
}