<template>
    <div class="container">
        <div>
            <h1>Applications</h1>

            <div class="text-center" v-if="showCreateSubmissionButton">
                <q-btn no-caps color="primary" @click="createSubmissionDialog = true">Create Submission</q-btn>
            </div>
        </div>

        <div>
            <q-table
            :columns="columns"
            :rows="currentSubmissions">
            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                        {{ col.label }}
                    </q-th>
                    <q-th auto-width>Actions</q-th>
                </q-tr>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td v-for="col in props.cols" :key="col.name" :props="props">
                        {{ col.value }}
                    </q-td>
                    <q-td auto-width>
                      <submission-actions :submission="props.row" :actions="props.row.actions" 
                                          @payment-successful="fetchSubmissions"  @submission-successful="fetchSubmissions"
                                          @requestApprovalSuccessful="fetchSubmissions"
                                          @approval-successful="fetchSubmissions"/>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
        </div>

        <q-dialog v-model="createSubmissionDialog">
            <CreateSubmissionForm :cycle="currentCycle" @submission-created="submissionCreated"/>
        </q-dialog>
    </div>
</template>
<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import { SubmissionDto } from '../../../types/models/submission/SubmissionDto';
import useApiClient from '../../../utils/api_client/ApiClient';
import { ProducerDashboardDto } from '../../../types/dto/ProducerDashboardDto';
import { CycleDto } from '../../../types/models/CycleDto';
import CreateSubmissionForm from "../../../components/submission/CreateSubmissionForm.vue";
import {storeToRefs} from "pinia";
import {useApplicationStore} from "../../../stores/appStore";
import SubmissionActions from "../../../components/submission/SubmissionActions.vue";


const { getRequest } = useApiClient();
const { profileNeedsUpdate } = storeToRefs(useApplicationStore());

const currentSubmissions = ref<SubmissionDto[]>([]);
const currentCycle = ref<CycleDto|undefined>(undefined);

const createSubmissionDialog = ref(false);

const columns = [
{
    'name': 'cycle',
    'label': 'Cycle',
    'field': 'cycleLabel'
},
{
    'name': 'status',
    'label': 'Status',
    'field': 'statusName',
},
{
  'name': 'awardLevel',
  'label': 'Award Level',
  'field': 'awardLevel',
}
];

const showCreateSubmissionButton = computed(() => {
    return !profileNeedsUpdate.value && currentCycle.value && currentSubmissions.value.filter(s => s.cycleId === currentCycle.value?.id).length === 0;
});

const submissionCreated = () => {
    createSubmissionDialog.value = false;
    fetchSubmissions();
};

const fetchSubmissions = async () => {
    const response = await getRequest<ProducerDashboardDto>('producer/dashboard');

    const producerDashboard = response.data;
    currentSubmissions.value = producerDashboard.submissions;
    currentCycle.value = producerDashboard.currentCycle;
};

onMounted(async () => {
    await fetchSubmissions();
});
    
</script>