﻿<template>
  <div class="flex justify-center">
    <q-card class="max-w-xl">
      <q-card-section>
        <div class="text-h6 bi-text-center">Payment</div>
        <div class="text-subtitle2">Please pay the following amount to submit your application for
          {{ submission.cycleLabel }}
        </div>
        <div class="text-h5">{{ formatCurrency(paymentPrice) }}</div>
      </q-card-section>
      <q-card-section>
        <div ref="stripeHtmlElement"/>
      </q-card-section>
      <q-card-actions>
        <div class="flex w-full">
          <q-btn no-caps label="Pay" class="flex-1" outline color="primary" @click="submitPayment"/>
          <q-btn v-if="isAdmin" no-caps label="Admin Skip Payment" class="w-full my-2" color="primary" @click="skipPayment"/>
          <q-btn :disable="busy" no-caps label="Cancel" class="w-full" outline @click="$emit('cancel')"/>
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>
<script lang="ts" setup>

import {SubmissionDto} from "../../types/models/submission/SubmissionDto";
import {onMounted, ref} from "vue";
import useApiClient from "../../utils/api_client/ApiClient";
import {useApplicationStore} from "../../stores/appStore";
import {StripePaymentIntentResponse} from "../../types/payments/StripePaymentIntentResponse";
import {formatCurrency} from "../../utils/currency/CurrencyHelpers";
import {loadStripe, Stripe, StripeElements} from "@stripe/stripe-js";
import {useRouter} from "vue-router";
import {ResponseMessageDto} from "../../types/ResponseMessageDto";
import {storeToRefs} from "pinia";

const {stripePublicKey, apiBaseUrl } = useApplicationStore()

const apiClient = useApiClient()
const { isAdmin } = storeToRefs(useApplicationStore())

const emits = defineEmits<{
  (e: 'payment-successful'): void,
  (e: 'cancel'): void,
}>()

const props = defineProps<{
  submission: SubmissionDto
}>()

const stripeHtmlElement = ref<HTMLElement>()
const stripe = ref<Stripe|null>(null)
const stripeElements = ref<StripeElements>()
const paymentPrice = ref<number>(0)
const busy = ref(false)

const submitPayment = async () => {
  try{
    busy.value = true
    const response = await stripe.value?.confirmPayment({
      elements: stripeElements.value as StripeElements,
      confirmParams: {
        return_url: `${apiBaseUrl}/producer/submissions/${props.submission.id}/after-pay`,
      }
    })

    if (response?.error) {
      console.error(response.error)
    }
    else {
      emits('payment-successful')
    }
  } finally {
    busy.value = false
  }
}

const skipPayment = async () => {
  try{
    busy.value = true
    await apiClient.postRequest(`admin/submissions/${props.submission.id}/skip-payment`, null)
    emits('payment-successful')
  } finally {
    busy.value = false
  }
}

onMounted(async () => {
  const response = await apiClient.postRequest<ResponseMessageDto<StripePaymentIntentResponse>>(`producer/submissions/${props.submission.id}/request-payment-intent`, null)

  stripe.value = await loadStripe(stripePublicKey)
  const {clientSecret, amount} = response.data.data

  const elements = stripe.value?.elements({
    clientSecret,
    appearance: {
      theme: 'stripe',
    }
  });

  stripeElements.value = elements

  const paymentElement = elements?.create("payment", {
    layout: 'tabs'
  });

  paymentElement?.mount(stripeHtmlElement.value as HTMLElement)
  paymentPrice.value = amount
})

</script>